// This file was generated by running 'ng generate @angular/material:m3-theme'.
// Proceed with caution if making changes to this file.

@use 'sass:map';
@use '@angular/material/index' as mat;
@include mat.core();
// Note: Color palettes are generated from primary: #4fc4ca, secondary: #f7ec3f
$_palettes: (
  primary: (
    0: #000000,
    10: #002021,
    20: #003739,
    25: #004346,
    30: #004f52,
    35: #005c60,
    40: #00696d,
    50: #00848a,
    60: #00a1a7,
    70: #1dbdc4,
    80: #4cd9e0,
    90: #6ff6fd,
    95: #befcff,
    98: #e6feff,
    99: #f2ffff,
    100: #ffffff,
  ),
  secondary: (
    0: #000000,
    10: #1e1c00,
    20: #353200,
    25: #403d00,
    30: #4d4800,
    35: #595400,
    40: #666000,
    50: #807a00,
    60: #9c9400,
    70: #b8af00,
    80: #d5cb16,
    90: #f2e73a,
    95: #fff568,
    98: #fffadf,
    99: #fffbff,
    100: #ffffff,
  ),
  tertiary: (
    0: #000000,
    10: #081c36,
    20: #1f314c,
    25: #2a3c58,
    30: #364764,
    35: #425370,
    40: #4e5f7d,
    50: #667897,
    60: #8091b1,
    70: #9aaccd,
    80: #b5c7e9,
    90: #d5e3ff,
    95: #ecf1ff,
    98: #f9f9ff,
    99: #fdfbff,
    100: #ffffff,
  ),
  neutral: (
    0: #000000,
    10: #191c1c,
    20: #2d3131,
    25: #383c3c,
    30: #444747,
    35: #505353,
    40: #5c5f5f,
    50: #747878,
    60: #8e9191,
    70: #a9acab,
    80: #c4c7c7,
    90: #e0e3e3,
    95: #eff1f1,
    98: #f7faf9,
    99: #fafdfc,
    100: #ffffff,
    4: #0b0f0f,
    6: #101414,
    12: #1d2020,
    17: #272b2b,
    22: #323535,
    24: #363a3a,
    87: #d8dada,
    92: #e6e9e8,
    94: #eceeee,
    96: #f2f4f4,
  ),
  neutral-variant: (
    0: #000000,
    10: #141d1e,
    20: #293233,
    25: #343d3e,
    30: #3f4949,
    35: #4a5455,
    40: #566061,
    50: #6f7979,
    60: #899393,
    70: #a3adad,
    80: #bec8c9,
    90: #dae4e5,
    95: #e9f3f3,
    98: #f1fbfb,
    99: #f4fefe,
    100: #ffffff,
  ),
  error: (
    0: #000000,
    10: #410002,
    20: #690005,
    25: #7e0007,
    30: #93000a,
    35: #a80710,
    40: #ba1a1a,
    50: #de3730,
    60: #ff5449,
    70: #ff897d,
    80: #ffb4ab,
    90: #ffdad6,
    95: #ffedea,
    98: #fff8f7,
    99: #fffbff,
    100: #ffffff,
  ),
);

$_rest: (
  secondary: map.get($_palettes, secondary),
  neutral: map.get($_palettes, neutral),
  neutral-variant: map.get($_palettes,  neutral-variant),
  error: map.get($_palettes, error),
);
$_primary: map.merge(map.get($_palettes, primary), $_rest);
$_tertiary: map.merge(map.get($_palettes, tertiary), $_rest);

$light-theme: mat.define-theme((
  color: (
    theme-type: light,
    primary: $_primary,
    tertiary: $_tertiary,
  ),
));

:root {
  @include mat.all-component-themes($light-theme);
}
