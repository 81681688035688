// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@import 'ngx-toastr/toastr';
@import "m3-theme";

// Comment out the line below if you want to use the pre-defined typography utility classes.
// For more information: https://material.angular.io/guide/typography#using-typography-styles-in-your-application.
// @include mat.typography-hierarchy($portal-ui-theme);

// Comment out the line below if you want to use the deprecated `color` inputs.
// @include mat.color-variants-backwards-compatibility($portal-ui-theme);
/* You can add global styles to this file, and also import other style files */
@tailwind base;
@tailwind components;
@tailwind utilities;
@import 'ckeditor5/ckeditor5.css';

body {
  margin: 0;
  font-family: Arial;
}

thead {
  background-color: rgba(29, 192, 203, 1) !important;
  border-top-left-radius: 1.5rem !important;
  border-top-right-radius: 1.5rem !important;

  tr {
    color: white !important;
  }
}

tbody {
  tr:hover {
    background: rgb(79 196 202 / 0.2);
    cursor: pointer;
  }
}

.mat-mdc-cell{
  border-bottom: 0px !important;
}

.mat-mdc-dialog-surface{
  background-color: rgb(243 244 246) !important;
}

.mat-stepper-vertical, .mat-stepper-horizontal{
  background-color: transparent !important;
}
.mat-mdc-dialog-content{
  padding: 0px !important;
}

::-webkit-scrollbar {
  width: 20px;
}

::-webkit-scrollbar-track {
  background-color: transparent;
}

::-webkit-scrollbar-thumb {
  background-color: #d6dee1;
  border-radius: 20px;
  border: 6px solid transparent;
  background-clip: content-box;
}
